<template>
  <div class="select" :class="wrapperClasses">
    <div class="top-labels">
      <div v-if="label" class="label">
        <span>{{ label }}</span>
      </div>
    </div>
    <div class="input-wrapper">
      <StIcon
        v-if="iconPrefix"
        data-t="icon-prefix"
        class="input-prefix-icon"
        :size="props.size === 'm' ? 20 : 16"
        :name="iconPrefix"
      />
      <div
        v-if="placeholder && !firstValue && !secondValue && !thirdValue"
        class="placeholder"
      >
        {{ placeholder }}
      </div>
      <div v-if="firstValue" class="first-value">
        {{ firstValue }}
      </div>
      <div v-if="secondValue" class="second-value">
        {{ secondValue }}
      </div>
      <div class="third-value">
        {{ thirdValue }}
      </div>
      <StIcon
        v-if="props.iconPostfix"
        class="postfix-icon"
        :name="props.iconPostfix"
        :size="props.size === 'm' ? 20 : 16"
        data-t="icon-postfix"
      />
    </div>
    <StTransitionExpand>
      <div v-if="hint || (error && errorMessage)">
        <span v-if="error && errorMessage" class="error-message" data-t="error">
          {{ errorMessage }}
        </span>
        <span v-else-if="hint" class="hint" data-t="hint">{{ hint }}</span>
      </div>
    </StTransitionExpand>
  </div>
</template>

<script setup lang="ts">
import type { IconName } from '@st/ui/components/StIcon/types'

type InputSize = 'm' | 's'

const props = withDefaults(
  defineProps<{
    firstValue?: string
    label?: string
    placeholder?: string
    hint?: string
    error?: boolean
    errorMessage?: string
    dataT?: string
    iconPrefix?: IconName
    iconPostfix?: IconName
    size: InputSize
    secondValue?: string
    thirdValue?: string
    platform?: 'desktop' | 'mobile'
    isNative?: boolean
  }>(),
  {
    firstValue: '',
    type: 'text',
    label: '',
    placeholder: '',
    hint: '',
    errorMessage: '',
    autocomplete: '',
    maxlength: 999,
    inputmode: 'text',
    size: 'm',
    notEditable: false,
    platform: 'desktop',
  },
)

const wrapperClasses = computed(() => ({
  [`size-${props.size}`]: props.size,
  [`platform-${props.platform}`]: props.platform,
  native: props.isNative,
}))

const minHeight = computed(() => {
  if (props.size === 's') return '32px'

  return props.platform === 'mobile' ? '40px' : '48px'
})

const iconPadding = computed(() => {
  if (props.size === 's') {
    return props.iconPrefix ? '36px' : '8px'
  }

  return props.iconPrefix ? '48px' : '16px'
})
</script>

<style scoped>
.select {
  position: relative;
  width: 100%;
  min-height: v-bind(minHeight);
}

.input-prefix-icon {
  color: var(--text-quaternary);
}

.input-wrapper {
  display: flex;
  gap: var(--spacing-100);
  align-items: center;
  justify-content: space-between;

  width: 100%;
  padding: var(--spacing-150);

  font: var(--desktop-text-md-medium);
  color: var(--text-primary);

  background-color: var(--background-secondary);
  border-radius: var(--border-radius-100);

  .second-value {
    color: var(--palette-light-600);
  }

  .postfix-icon {
    color: var(--palette-light-600);
  }

  .third-value {
    flex-grow: 1;
    text-align: right;
  }

  .placeholder {
    margin-left: var(--spacing-050);
    font: var(--desktop-text-md-medium);
    color: var(--text-quaternary);
  }

  &:hover {
    cursor: pointer;
    box-shadow: 0 0 0 1px var(--button-primary-default) inset;
  }

  &:focus {
    box-shadow:
      0 0 0 1px var(--button-primary-default) inset,
      var(--focus-rings-button-primary);

    .postfix-icon {
      color: var(--palette-light-1000);
    }
  }

  &.error {
    box-shadow: 0 0 0 1px var(--system-error) inset;
  }

  &:focus.error {
    box-shadow:
      0 0 0 1px var(--system-error) inset,
      var(--focus-rings-button-destructive);
  }
}

.hint {
  margin-top: var(--spacing-100);
  font: var(--desktop-text-xs-medium);
  color: var(--text-secondary);
}

.error-message {
  margin-top: var(--spacing-100);
  font: var(--desktop-text-xs-medium);
  color: var(--system-error);
}

.label {
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-bottom: var(--spacing-075);

  font: var(--desktop-text-sm-medium);
  color: var(--text-secondary);
}

.select.size-s {
  .input {
    padding: var(--spacing-100);
    padding-left: v-bind(iconPadding);
    font: var(--desktop-text-xs-medium);

    &::placeholder {
      font: var(--desktop-text-xs-medium);
    }
  }

  .input-wrapper {
    padding: var(--spacing-100);
    font: var(--desktop-text-xs-semibold);
  }

  .label {
    font: var(--desktop-text-xs-medium);
  }

  .placeholder {
    font: var(--desktop-text-xs-medium);
    color: var(--text-quaternary);
  }
}

.first-value {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.select.platform-mobile {
  .input-wrapper {
    padding: var(--spacing-125);
  }

  .placeholder,
  .first-value,
  .second-value {
    font: var(--desktop-text-sm-medium);
  }

  .third-value {
    font: var(--desktop-text-sm-semibold);
  }
}

.select.native {
  .placeholder {
    color: var(--text-primary);
  }
}
</style>
